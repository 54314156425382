.ModalOverlay {
  position: fixed;
  inset: 0px;
  background-color: rgba(50, 54, 61, 0.3);
  backdrop-filter: blur(10px);
  z-index: 2000;
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ModalContent {
  position: absolute;
  inset: 40px;
  overflow: auto;
  outline: none;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);
  background: none;
  box-shadow: none;
  padding: 16px;
  width: fit-content;
}

@media screen and (max-width: 568px) {
  .ModalContent {
    width: 100%;
    max-width: 100%;
  }
}

.ModalOverlay__after-open,
.ModalContent__after-open {
  opacity: 1;
}

.ModalOverlay__before-close,
.ModalContent__before-close {
  opacity: 0;
}
