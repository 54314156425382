.tooltipTheme {
  color: #ffffff !important;
  background-color: #45484f !important;
  font-family: 'Poppins', sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  line-height: 15px !important;
  text-align: center !important;
  max-width: 178px !important;
  border-radius: 8px !important;
  padding: 10px !important;
}

.tooltipTheme.place-top:after {
  border-top-color: #45484f !important;
  border-top-style: solid !important;
  border-top-width: 6px !important;
}
